
import {
  Component, Prop, Ref, Vue, Watch,
} from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import ViewModel from '@/models/ViewModel'

import Widget from '@/components/Widget/Widget.vue'
import DataTable from '@/components/DataTable/index.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'

import Opportunity from '@/models/Opportunity'
import IconAction from '@/components/IconAction/IconAction.vue'
import { watch } from 'vue'
import opportunities_home_table_fields from '../opportunities-home-table-fields'

@Component({
  components: {
    Widget,
    DataTable,
    SearchInput,
    IconAction,
  },
})
// make documentation about this component
export default class OpportunityTable extends ViewModel {
  @Ref() readonly dataTable!: any

  @Prop({ default: null })
  public company!: any

  @Prop({ default: false })
  public isLoading!: boolean

  @Prop({ default: () => [] })
  public hideColumns!: any

  @Prop()
  public extraFieldFilters!: any

  @Prop()
  public extraQuery!: any // when we have and array on this input it will be added to this.query in loadFilters()

  @Prop({ default: false })
  public resetFiltersOnMounted!: boolean

  @Watch('isLoading')
  public onIsLoadingChange(val: boolean) {
    this.loading = val
  }

  public sort_by: string = 'created_at'

  public sort_desc: boolean = true

  public busy: boolean = true

  public query: string[] = ['is:mine']

  public page_size: number = 25

  public page: number = 1

  public records: number = 0

  public loading: boolean = true

  public ready: boolean = false

  public show_filter_helper: boolean = false

  public fields: any = []

  public fieldFilters: any = {
    start_at: '',
    end_at: '',
    created_at: '',
    updated_at: '',
    status: '',
  }

  public query_settings: any = {
    company_fields: [
      {
        name: 'agency',
        key: 'agency_id',
        type: 'agency',
        description: 'Filter by Agency',
      },
      {
        name: 'station',
        key: 'station_id',
        type: 'station',
        description: 'Filter by Station',
      },
      {
        name: 'advertiser',
        key: 'advertiser_id',
        type: 'advertiser',
        description: 'Filter by Advertiser',
      },
    ],
    user_fields: [
      {
        name: 'sm',
        key: 'sales_management_id',
        description: 'Filter by Sales Manager',
      },
      {
        name: 'sr',
        key: 'sales_rep_id',
        description: 'Filter by Sales Rep',
      },
      {
        name: 'am',
        key: 'account_manager_id',
        description: 'Filter by Account Manager',
      },
    ],
    custom_fields: [
      {
        name: 'is:mine',
        value: 'is:mine',
        description: 'Filter by My Opportunities',
      },
    ],
  }

  public get show_fields() {
    return this.fields.filter((f: any) => f.show && !this.hideColumns.includes(f.key))
  }

  public get filter_fields() {
    if (!this.hideColumns || !this.hideColumns.length) return this.fields
    return this.fields.filter((f: any) => !this.hideColumns.includes(f.key))
  }

  public set filter_fields(value: any) {
    this.fields = value
  }

  public belongsToId() {
    let { type } = this.company
    return `${type}_id:${this.company.id}`
  }

  public opportunities(context: any) {
    this.loading = true

    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key].toLowerCase()}`)

    this.syncFilters()

    let q: any = []

    if (this.company) {
      q = q.concat(this.belongsToId())
    }

    if (this.$attrs.request_query) {
      q = q.concat(this.$attrs.request_query)
    }

    return Opportunity.paginate({
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: [...context.filter, ...field_filters, ...q],
    }).then(result => {
      this.records = result.records
      this.loading = false
      return result.data
    })
  }

  mounted() {
    if (this.resetFiltersOnMounted) {
      this.clearFilters()
    }

    this.fields = opportunities_home_table_fields

    this.loadFilters()
  }

  public syncFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'opportunities',
      data: { query: this.query, fieldFilters: this.fieldFilters },
    })
  }

  public loadFilters() {
    const system = getModule(SystemtModule)
    system.getFilter('opportunities').then((filter: any) => {
      if (filter) {
        this.query = filter.query
        this.fieldFilters = filter.fieldFilters
      }
      if (this.extraFieldFilters && Object.keys(this.extraFieldFilters).length) {
        this.fieldFilters = { ...this.fieldFilters, ...this.extraFieldFilters }
      }
      if (this.extraQuery && this.extraQuery.length) {
        if (!this.query) this.query = []
        this.query = this.query.concat(this.extraQuery)
      }

      this.ready = true

      setTimeout(() => {
        this.busy = false
      }, 500)
    })
  }

  public clearFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'opportunities',
      data: null,
    })
  }

  public refresh() {
    this.dataTable.refresh()
  }

  public resetFilters(refresh: boolean = true) {
    this.fieldFilters = {
      start_at: '',
      end_at: '',
      created_at: '',
      updated_at: '',
      status: '',
    }
    this.query = ['is:mine']
    this.clearFilters()
    if (refresh) this.refresh()
  }

  public newOpportunity() {
    let { type, id } = this.company

    let query: any = {
      type,
      action: 'new',
    }
    if (this.$attrs.route_ref) {
      query.ref = this.$attrs.route_ref.name
      query.id = this.$attrs.route_ref.id
    }
    this.$router.push({
      name: 'opportunity',
      params: {
        id,
      },
      query,
    })
  }
}
