import router from '@/Routes'
import FinancialPlan from '@/models/FinancialPlan'

export default [
  // {
  //   key: 'sales_manager',
  //   label: 'Sales Manager',
  //   sortable: true,
  //   filter: true,
  //   show: true,
  //   type: 'object',
  //   object: {
  //     key: 'sales_rep',
  //     property: 'name',
  //   },
  //   thClass: 'align-middle text-center',
  //   tdClass: 'align-middle text-center',
  // },
  {
    key: 'sales_rep',
    label: 'Sales Rep',
    sortable: true,
    filter: true,
    show: true,
    type: 'object',
    object: {
      key: 'sales_rep',
      property: 'name',
    },
    thClass: 'align-middle text-center',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'product',
    label: 'Product',
    sortable: true,
    filter: true,
    show: true,
    type: 'uppercase',
    thClass: 'align-middle text-center',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'last_year_goal_achieved',
    label: 'Last Year Achieved',
    sortable: true,
    filter: true,
    show: true,
    type: 'currency',
    thClass: 'align-middle text-center',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'view_goal',
    label: 'Goal',
    sortable: true,
    filter: true,
    show: true,
    thClass: 'align-middle text-center',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'view_goal_pipeline',
    label: 'Pipeline',
    sortable: true,
    filter: true,
    show: true,
    thClass: 'align-middle text-center',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'view_goal_sold',
    label: 'Sold',
    sortable: true,
    filter: true,
    show: true,
    thClass: 'align-middle text-center',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'view_goal_achieved',
    label: 'Achieved',
    sortable: true,
    filter: true,
    show: true,
    thClass: 'align-middle text-center',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'share',
    label: 'Share',
    sortable: true,
    filter: false,
    show: true,
    thClass: 'align-middle text-center',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'action',
    label: '',
    type: 'action',
    sortable: false,
    show: true,
    tdClass: 'actions',
    thStyle: { width: '160px' },
    actions: [
      {
        icon: 'handshake',
        title: 'Opportunities',
        event: 'view-opportunities',
        top_level: true,
      },
      {
        icon: 'pencil',
        title: 'Edit Year Goal',
        event: 'edit',
        top_level: true,
      },
      {
        icon: 'trash',
        title: 'Delete Year Goal',
        event: 'delete',
        top_level: true,
      },
    ],
  },
]
