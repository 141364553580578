
import CompanyPicker from '@/components/CompanyPicker/CompanyPicker.vue'
import DataTable from '@/components/DataTable/index.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
import PageHeader from '@/components/PageHeader/PageHeader.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import UserPicker from '@/components/UserPicker/UserPicker.vue'
import Widget from '@/components/Widget/Widget.vue'
import ViewModel from '@/models/ViewModel'
import {
  currencyMask,
  percentagDecimaleMask,
  percentagDecimalNegativeMask,
} from '@/models/interface/Masks'
import {
  Component, Prop, Watch, Ref, Vue,
} from 'vue-property-decorator'
import FooterNav from '@/components/FooterNav/FooterNav.vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import MediaPackagePicker from '@/components/MediaPackagePicker/MediaPackagePicker.vue'
import FinancialPlan from '@/models/FinancialPlan'
import { RegionOptions, Products } from '@/models/interface/Common'
import WebMessage from '@/models/WebMessage'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import {
  capitalize, clone, groupBy, keyBy, orderBy,
} from 'lodash'
import moment from 'moment'
import BigNumber from 'bignumber.js'
// @ts-ignore
import { Md5 } from 'ts-md5/dist/md5'
import User from '@/models/User'
import { toRaw } from 'vue'
import OpportunityTable from '../Opportunity/components/OpportunityTable.vue'
import RegionFields from './financial-plan-region-fields'
import AgencyFields from './financial-plan-agency-fields'
import SalesRepsFields from './financial-plan-sales-rep-fields'

@Component({
  components: {
    PageHeader,
    Widget,
    FormInput,
    CompanyPicker,
    SelectPicker,
    UserPicker,
    DataTable,
    IconAction,
    OpportunityTable,
    FooterNav,
    DatePicker,
    MediaPackagePicker,
    SearchInput,
  },
})
export default class GoalPlan extends ViewModel {
  // dataTable Ref
  @Ref() readonly dataTable!: DataTable

  @Ref() readonly overviewTable!: HTMLFormElement

  @Prop()
  public year!: number

  @Prop({ default: null })
  public region!: string | null

  @Prop({ default: null })
  public agency!: string | null

  @Watch('year')
  public onYearChange() {
    this.refresh()
  }

  @Watch('region')
  public onRegionChange() {
    this.refresh()
  }

  @Watch('agency')
  public onAgencyChange() {
    this.refresh()
  }

  public ignore_action_change = false

  @Watch('seek_goal.action')
  public onSeekGoalActionChange(val: string, old: string) {
    if (this.ignore_action_change) {
      this.ignore_action_change = false
      return
    }
    if (
      (val === 'advanced' || old === 'advanced')
      && this.advanced_checksum != ''
      && this.advanced_checksum !== this.calculatedAdvancedChecksum
    ) {
      WebMessage.confirm(
        'You have unsaved changes, do you want to discard them?',
        'Unsaved changes',
        { okTitle: 'Discard' },
      ).then((value: boolean) => {
        if (value) {
          this.reloadAdvancedAction()
        } else {
          this.ignore_action_change = true
          this.seek_goal.action = 'advanced'
        }
      })
    } else {
      if (val !== 'advanced') {
        this.advanced_checksum = ''
      }
      this.reloadAdvancedAction()
    }
  }

  @Watch('seek_goal.product')
  public onSeekGoaProductChange() {
    if (this.advanced_checksum !== this.calculatedAdvancedChecksum) {
      WebMessage.confirm(
        'You have unsaved changes, do you want to discard them?',
        'Unsaved changes',
        { okTitle: 'Discard' },
      ).then((value: boolean) => {
        if (value) {
          this.reloadAdvancedAction()
        }
      })
    } else {
      this.reloadAdvancedAction()
    }
  }

  public reloadAdvancedAction() {
    if (this.seek_goal.action !== 'advanced') {
      return
    }
    this.seek_goal.loading = true
    return FinancialPlan.paginate({
      page_size: 'all',
      page: 1,
      order_by: 'period_start_at',
      order: 'asc',
      query: [`parent_id:${this.model.id}`, `product:${this.seek_goal.product}`],
    }).then((response: any) => {
      let base = groupBy(response.data, 'sales_rep_id')

      // this.seek_goal.amount = response.data.reduce((acc: any, item: any) => {
      //   acc += Number(item.goal)
      //   return acc
      // }, 0)

      let reps = []
      for (let key in base) {
        let rep = this.buildBaseSalesRepItems({
          sales_rep_id: key,
          sales_rep: base[key][0].sales_rep,
          parent_id: this.model.id,
          type: 'sales_rep_goal',
          product: this.seek_goal.product,
        })

        base[key].forEach((item: any) => {
          let date = moment(item.period_start_at).format('YYYY-MM-DD')
          let idx = rep.findIndex((i: any) => i.period_start_at === date)

          if (idx >= 0) {
            rep[idx] = FinancialPlan.toObject(item)
          }
        })

        reps.push(...rep)
      }

      let groups = groupBy(reps, (item: any) => moment(item.period_start_at).format('MMM, YYYY'))

      for (let key in groups) {
        // @ts-ignore
        groups[key] = keyBy(groups[key], 'sales_rep_id')
      }

      this.advanced_models = groups

      this.seek_goal.loading = false

      this.advanced_delete = []

      this.advanced_checksum = this.calculatedAdvancedChecksum
    })
  }

  public getAdvancedTotal(type: string, key: string = '') {
    let total = 0
    if (type === 'month') {
      for (let rep in this.advanced_models[key]) {
        total += Number(this.advanced_models[key][rep].goal)
      }
    }
    if (type === 'total') {
      for (let month in this.advanced_models) {
        for (let rep in this.advanced_models[month]) {
          total += Number(this.advanced_models[month][rep].goal)
        }
      }
    } else {
      for (let month in this.advanced_models) {
        for (let rep in this.advanced_models[month]) {
          if (rep === key) {
            total += Number(this.advanced_models[month][rep].goal)
          }
        }
      }
    }

    return total
  }

  public modals: any = {
    sales_rep_opportunity: false,
    goal_seek: false,
  }

  public temp_sales_rep: any = {
    data: null,
    query: null,
  }

  public agency_name: string = ''

  public plan = new FinancialPlan()

  public parent_plan = new FinancialPlan()

  public model = new FinancialPlan()

  public items: FinancialPlan[] = []

  public locked_items: string[] = []

  // dataTable field filters
  public fieldFilters: any = {}

  public share_target: number = 0

  public preview_region: boolean = false

  public is_searching: boolean = false

  public sidebar: boolean = false

  public is_side_bar_open: boolean = false

  public ready: boolean = false

  public sales_rep_details: boolean = false

  // Total Number of records
  public records: number = 0

  public calculated_totals: any = {}

  public page_scope: any = 'region'

  public overview_data: any[] = []

  public overview_last_year: any[] = []

  @Watch('$route', { immediate: true, deep: true })
  public onUrlChange(newVal: any) {
    this.fieldFilters = {}
    setTimeout(() => {
      this.calculateTotals()
    }, 500)
  }

  public get snapshot_id() {
    return this.$route.query.snapshot
  }

  public get snapshot_revision() {
    return this.$route.query.revision
  }

  public get is_snapshot() {
    return !!this.snapshot_id
  }

  public get snapshot_query() {
    return this.is_snapshot ? { snapshot: this.snapshot_id } : {}
  }

  public get overview_fields() {
    const pointer = moment(`${this.year}-01-01`)

    let ret: any[] = [
      {
        label: '',
        key: 'label',
        thClass: 'align-middle text-center',
        tdClass: 'align-middle text-center',
      },
    ]
    let quarter = 0
    while (pointer.year() == this.year) {
      if (this.overview_columns.includes('month')) {
        ret.push({
          label: pointer.format('MMM'),
          key: pointer.format('MMM'),
          type: 'currency',
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
        })
      }
      pointer.add(1, 'month').startOf('month')
      let is_end_of_quarter = pointer.month() % 3 === 0
      if (is_end_of_quarter && this.overview_columns.includes('quarter')) {
        quarter++
        ret.push({
          label: `Q${quarter}`,
          key: `Q${quarter}`,
          type: 'currency',
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          variant: 'active',
        })
      }
    }

    ret.push({
      name: 'Total',
      key: 'Total',
      type: 'currency',
      thClass: 'align-middle text-center',
      tdClass: 'align-middle text-center',
      variant: 'active',
    })

    return ret
  }

  public query_settings: any = {
    company_fields: [
      {
        name: 'company',
        key: 'company_id',
      },
      {
        name: 'agency',
        key: 'agency_id',
      },
    ],
    user_fields: [
      {
        name: 'sr',
        key: 'sales_rep_id',
      },
    ],
  }

  public advanced_checksum: string = ''

  public advanced_delete: string[] = []

  public get calculatedAdvancedChecksum(): string {
    let base = ''
    for (let month in this.advanced_models) {
      for (let rep in this.advanced_models[month]) {
        base += `${month}-${rep}-${this.advanced_models[month][rep].goal}`
      }
    }

    base += this.advanced_delete.join(',')
    return Md5.hashStr(base)
  }

  public advanced_models: any = {}

  public overview_columns: any = ['month', 'quarter']

  public overview_columns_options: any = [
    { text: 'Month', value: 'month' },
    { text: 'Quarter', value: 'quarter' },
  ]

  public overview_products_options: any = [
    { text: 'SSL', value: 'ssl' },
    { text: 'CCL', value: 'ccl' },
  ]

  public overview_products: any = ['ssl', 'ccl']

  public overview_regions: any = ['national', 'east', 'west', 'midwest']

  public seek_goal: any = {
    action: 'set',
    target: 'goal',
    source: 'planned',
    product: 'ssl',
    amount: 0,
    loading: false,
    sub_loading: false,
  }

  public get region_options() {
    return this.options.regions.map(r => ({
      text: r.name,
      value: r.value,
    }))
  }

  public get seek_goal_actions(): any {
    let ret = [
      { text: 'Match', value: 'match' },
      { text: 'Set', value: 'set' },
      { text: 'Adjust', value: 'adjust' },
    ]
    if (this.scope === 'agency') {
      ret.push({ text: 'Advanced', value: 'advanced' })
    }

    return ret
  }

  public seek_goal_sources: any = [
    { text: 'Plan', value: 'goal' },
    { text: 'Pending Plan', value: 'planned' },
  ]

  public advanced_remove_action = 'discard'

  public advanced_remove_options: any = [
    { name: 'Discard Plan', value: 'discard' },
    { name: 'Distribute Evenly', value: 'even' },
    { name: 'Distribute Proportionally', value: 'proportional' },
    { name: 'Move to Sales Rep', value: 'replace' },
  ]

  public get seek_goal_mask() {
    if (this.seek_goal.action === 'set' || this.seek_goal.action === 'advanced') {
      return currencyMask
    }
    return percentagDecimalNegativeMask
  }

  public sales_rep_items: FinancialPlan[] = []

  public get parent_scope(): string {
    if (this.page_scope === 'region') {
      return 'year'
    }
    if (this.page_scope === 'agency') {
      return 'region'
    }
    return 'agency'
  }

  public get scope(): string {
    if (this.agency) {
      return 'sales_rep'
    }
    if (this.region) {
      return 'agency'
    }
    return 'region'
  }

  public get sidebar_title() {
    let action = this.model.id ? 'Edit' : 'New'

    if (this.page_scope === 'region') {
      return `${action} Region`
    }
    if (this.page_scope === 'agency') {
      return `${action} Client`
    }
    return `${action} Sales Rep`
  }

  public get fields() {
    let ret = []
    if (this.page_scope === 'sales_rep') {
      ret = SalesRepsFields
    } else if (this.page_scope === 'agency') {
      ret = AgencyFields
    } else {
      ret = RegionFields
    }

    if (this.is_snapshot) {
      ret = ret.map((item: any) => {
        if (item.type === 'action') {
          item.actions = item.actions.filter((action: any) => action.allow_on_snapshot)
        }
        return item
      })
    }

    return ret
  }

  public get options() {
    return {
      regions: RegionOptions,
      products: Products,
    }
  }

  public get masks() {
    return {
      currency: currencyMask,
      percentage: percentagDecimaleMask,
    }
  }

  public get pageGoalScope() {
    return `${this.page_scope}_goal`
  }

  public get salesRepItemsTotal() {
    return this.sales_rep_items.reduce((a, b) => a + (Number(b.goal) ?? 0), 0)
  }

  public get card_info() {
    let title = ''
    let data = this.items.length
    if (this.$route.params.year && !this.$route.params.region) {
      title = 'Regions'
      this.page_scope = 'region'
    }
    if (this.$route.params.year && this.$route.params.region) {
      title = 'Clients'
      this.page_scope = 'agency'
    }
    if (this.$route.params.year && this.$route.params.region && this.$route.params.agency) {
      title = 'Sales Reps'
      this.page_scope = 'sales_rep'
    }
    return {
      title,
      data,
    }
  }

  public get card_title() {
    if (this.scope === 'region') {
      return 'Year'
    }

    if (this.scope === 'agency') {
      return 'Region'
    }

    return 'Client'
  }

  public refresh() {
    this.dataTable.refresh()
  }

  public toogleLocks(key: string = '') {
    if (key != '') {
      if (this.locked_items.includes(key)) {
        this.locked_items = this.locked_items.filter(item => item !== key)
      } else {
        this.locked_items.push(key)
      }
      return
    }

    if (this.locked_items.length > 0) {
      this.locked_items = []
    } else {
      this.locked_items = this.sales_rep_items.map(item => item.period)
    }
  }

  public batchLock(target: string, key: string = '') {
    if (target === 'all') {
      for (let period in this.advanced_models) {
        for (let rep in this.advanced_models[period]) {
          this.toogleLocks(`${period}-${rep}`)
        }
      }
    } else if (target === 'period') {
      for (let rep in this.advanced_models[key]) {
        this.toogleLocks(`${key}-${rep}`)
      }
    } else if (target === 'rep') {
      for (let period in this.advanced_models) {
        this.toogleLocks(`${period}-${key}`)
      }
    }
  }

  public advancedReset() {
    if (this.advanced_checksum !== this.calculatedAdvancedChecksum) {
      WebMessage.confirm(
        'You have unsaved changes, do you want to discard them? This action will reset all the values to the original ones.',
        'Unsaved changes',
        { okTitle: 'Discard' },
      ).then((value: boolean) => {
        if (value) {
          this.locked_items = []
          this.reloadAdvancedAction()
        }
      })
    } else {
      this.locked_items = []
      this.reloadAdvancedAction()
    }
  }

  public async addAdvancedSalesRep(internal = false) {
    this.seek_goal.sub_loading = true

    if (!this.advanced_models[`Jan, ${this.year}`]) {
      let pointer = moment(`${this.year}-01-01`).startOf('year')

      while (pointer.year() == this.year) {
        let date = pointer.format('MMM, YYYY')
        this.advanced_models[date] = {}
        pointer.add(1, 'month')
      }
    }

    // Check if rep is already in the list
    if (!this.advanced_models[`Jan, ${this.year}`][this.model.sales_rep_id]) {
      let user = await User.find(this.model.sales_rep_id)
      // If not add it
      let rep = this.buildBaseSalesRepItems({
        sales_rep_id: this.model.sales_rep_id,
        sales_rep: user,
        parent_id: this.model.id,
        type: 'sales_rep_goal',
        product: this.seek_goal.product,
      })
      for (let period in this.advanced_models) {
        let idx = rep.findIndex(
          (i: any) => moment(i.period_start_at).format('MMM, YYYY') === period,
        )

        if (idx >= 0) {
          this.advanced_models[period][this.model.sales_rep_id] = rep[idx]
        }
      }
    }
    if (!internal) {
      this.model.sales_rep_id = null
      this.seek_goal.sub_loading = false
    }
  }

  public async removeAdvancedSalesRep(rep: string) {
    if (this.advanced_remove_action === 'replace' && !this.model.sales_rep_id) {
      WebMessage.error('You must select a sales rep')
      return
    }

    if (this.advanced_remove_action === 'replace' && this.model.sales_rep_id == rep) {
      WebMessage.error('You must select a different sales rep')
      return
    }

    this.seek_goal.sub_loading = true

    if (this.advanced_remove_action === 'discard') {
      for (let period in this.advanced_models) {
        if (this.advanced_models[period][rep].id) {
          this.advanced_delete.push(this.advanced_models[period][rep].id)
        }
        delete this.advanced_models[period][rep]
      }
    } else if (this.advanced_remove_action === 'replace') {
      this.addAdvancedSalesRep(true)

      // Check if rep is already in the list
      if (!this.advanced_models[`Jan, ${this.year}`][this.model.sales_rep_id]) {
        let user = await User.find(this.model.sales_rep_id)
        // If not add it
        let rep = this.buildBaseSalesRepItems({
          sales_rep_id: this.model.sales_rep_id,
          sales_rep: user,
          parent_id: this.model.id,
          type: 'sales_rep_goal',
          product: this.seek_goal.product,
        })
        for (let period in this.advanced_models) {
          let idx = rep.findIndex(
            (i: any) => moment(i.period_start_at).format('MMM, YYYY') === period,
          )

          if (idx >= 0) {
            this.advanced_models[period][this.model.sales_rep_id] = rep[idx]
          }
        }
      }

      for (let period in this.advanced_models) {
        this.advanced_models[period][this.model.sales_rep_id].goal += Number(
          this.advanced_models[period][rep].goal,
        )
        if (this.advanced_models[period][rep].id) {
          this.advanced_delete.push(this.advanced_models[period][rep].id)
        }
        delete this.advanced_models[period][rep]
      }
    } else {
      for (let period in this.advanced_models) {
        if (this.advanced_models[period][rep].id) {
          this.advanced_delete.push(this.advanced_models[period][rep].id)
        }
        let share = new BigNumber(1).div(Object.keys(this.advanced_models[period]).length - 1)

        let total = this.getAdvancedTotal('month', period) - this.advanced_models[period][rep].goal
        for (let rep_id in this.advanced_models[period]) {
          if (rep_id !== rep) {
            if (this.advanced_remove_action === 'even') {
              this.advanced_models[period][rep_id].goal = new BigNumber(
                this.advanced_models[period][rep_id].goal,
              )
                .plus(new BigNumber(this.advanced_models[period][rep].goal).times(share))
                .toFixed(2)
            } else {
              this.advanced_models[period][rep_id].goal = new BigNumber(
                this.advanced_models[period][rep_id].goal,
              )
                .plus(
                  new BigNumber(this.advanced_models[period][rep].goal).times(
                    new BigNumber(this.advanced_models[period][rep_id].goal).div(total),
                  ),
                )
                .toFixed(2)
            }
          }
        }

        delete this.advanced_models[period][rep]
      }
    }

    this.model.sales_rep_id = null
    setTimeout(() => {
      this.seek_goal.sub_loading = false
    }, 500)
  }

  public advancedGoalSeek(action: string) {
    if (action === 'even') {
      let record_count = 0
      let locked = 0
      for (let period in this.advanced_models) {
        for (let rep in this.advanced_models[period]) {
          if (!this.locked_items.includes(`${period}-${rep}`)) {
            record_count++
          } else {
            locked += Number(this.advanced_models[period][rep].goal)
          }
        }
      }

      if (locked > this.seek_goal.amount) {
        WebMessage.error('The amount you entered is less than the locked amount')
        return
      }

      let amount = +new BigNumber(this.seek_goal.amount)
        .minus(locked)
        .dividedBy(record_count)
        .toFixed(2)

      let remaining = +new BigNumber(amount)
        .times(record_count)
        .plus(locked)
        .minus(this.seek_goal.amount)
        .times(-1)
        .toFixed(2)

      this.seek_goal.sub_loading = true
      for (let period in this.advanced_models) {
        for (let rep in this.advanced_models[period]) {
          if (!this.locked_items.includes(`${period}-${rep}`)) {
            this.advanced_models[period][rep].goal = Number((amount + remaining).toFixed(2))
            remaining = 0
          }
        }
      }
      setTimeout(() => {
        this.seek_goal.sub_loading = false
      }, 500)

      return
    }

    // Proportional
    let locked = 0
    let total = 0
    for (let period in this.advanced_models) {
      for (let rep in this.advanced_models[period]) {
        if (this.locked_items.includes(`${period}-${rep}`)) {
          locked += Number(this.advanced_models[period][rep].goal)
        }
        total += Number(this.advanced_models[period][rep].goal)
      }
    }

    if (locked > this.seek_goal.amount) {
      WebMessage.error('The amount you entered is less than the locked amount')
    }

    let remaining = total - locked
    let target = this.seek_goal.amount - locked

    this.seek_goal.sub_loading = true

    let applied = 0
    for (let period in this.advanced_models) {
      for (let rep in this.advanced_models[period]) {
        if (!this.locked_items.includes(`${period}-${rep}`)) {
          this.advanced_models[period][rep].goal = new BigNumber(
            this.advanced_models[period][rep].goal,
          )
            .div(remaining)
            .times(target)
            .toFixed(2)

          applied += Number(this.advanced_models[period][rep].goal)
        }
      }
    }

    setTimeout(() => {
      this.seek_goal.sub_loading = false
    }, 500)
  }

  public salesRepGoalSeek(idx: number) {
    if (idx < 0) {
      let available_amount = this.sales_rep_items.reduce((a, b) => {
        if (this.locked_items.includes(b.period)) {
          return a
        }

        return a + Number(b.goal)
      }, 0)

      let diff = this.share_target - this.salesRepItemsTotal

      if (-diff > available_amount) {
        WebMessage.error('The amount you entered is less than the available amount')
        return
      }

      // Rebalance unlocked items so the total match the target
      this.sales_rep_items = this.sales_rep_items.map(item => {
        let share = item.goal / available_amount
        if (this.locked_items.includes(item.period)) {
          return item
        }

        item.goal = Number((Number(item.goal) + diff * share).toFixed(2))

        return item
      })

      return
    }

    if (this.locked_items.includes(this.sales_rep_items[idx].period)) {
      WebMessage.error('The period you selected is locked')
      return
    }

    if (this.share_target > 100 || this.share_target < 0) {
      WebMessage.error('The percentage must be between 0% and 100%')
      return
    }

    let available_amount = this.sales_rep_items.reduce((a, b) => {
      if (this.locked_items.includes(b.period)) {
        return a
      }

      return a + Number(b.goal)
    }, 0)

    let original = clone(this.salesRepItemsTotal)
    let amount = Number((original * (this.share_target / 100)).toFixed(2))

    if (available_amount < amount) {
      WebMessage.error('The amount you entered is greater than the available amount')
      return
    }

    // Apply ammount
    this.sales_rep_items[idx].goal = amount

    // Get new total
    let new_total = clone(this.salesRepItemsTotal)

    // Get diff
    let diff = original - new_total

    // Calculate available amount to rebalance
    available_amount = this.sales_rep_items.reduce((a, b) => {
      if (this.locked_items.includes(b.period) || b.period === this.sales_rep_items[idx].period) {
        return a
      }

      return a + Number(b.goal)
    }, 0)

    // Rebalance unlocked items so the total is the same as the original
    this.sales_rep_items = this.sales_rep_items.map(item => {
      let share = item.goal / available_amount
      if (
        this.locked_items.includes(item.period)
        || item.period === this.sales_rep_items[idx].period
      ) {
        return item
      }

      item.goal = Number((Number(item.goal) + diff * share).toFixed(2))

      return item
    })
  }

  public buildBaseSalesRepItems(overwrites = {}) {
    let ret: FinancialPlan[] = []
    let pointer = moment(`${this.year}-01-01`)

    while (pointer.year() == this.model.year) {
      const o = FinancialPlan.toObject({
        ...this.model,
        ...{
          id: '',
          goal: 0,
          goal_planned: 0,
          goal_pipeline: 0,
          goal_achieved: 0,
          last_year_goal: 0,
          last_year_planned: 0,
          last_year_pipeline: 0,
          last_year_achieved: 0,
          period_start_at: pointer.format('YYYY-MM-DD'),
          period_end_at: pointer.endOf('month').format('YYYY-MM-DD'),
        },
        ...overwrites,
      })

      o.id = ''
      ret.push(o)
      pointer = pointer.add(1, 'month').startOf('month')
    }

    return ret
  }

  @Watch('overview_products')
  public onOverviewProductsChange(val: string[], old: string[]) {
    if (this.overview_products.length === 0) {
      for (let p of this.overview_products_options) {
        if (!old.includes(p.value)) {
          this.overview_products.push(p.value)
          return
        }
      }
      return
    }
    this.overviewTable.refresh()
  }

  @Watch('overview_regions')
  public onOverviewRegionsChange(val: string[], old: string[]) {
    if (this.overview_regions.length === 0) {
      for (let p of this.region_options) {
        if (!old.includes(p.value)) {
          this.overview_regions.push(p.value)
          return
        }
      }
      return
    }
    this.overviewTable.refresh()
  }

  public overviewRows() {
    return FinancialPlan.overview(
      this.year,
      this.overview_regions,
      this.overview_products,
      this.snapshot_id,
    )
      .then(response => response.data.result)
      .catch(() => [])
  }

  public loadWidgets() {
    let query = []

    query.push(`year:${this.year}`)
    if (this.region) {
      query.push(`region:${this.region}`)
    }
    if (this.agency) {
      query.push(`agency_id:${this.agency}`)
    }

    query.push(`scope:${this.parent_scope}`)

    return FinancialPlan.paginate({
      page_size: 1,
      page: 1,
      order_by: 'year',
      order: 'asc',
      query,
      // @ts-ignore
      snapshot: this.snapshot_id,
    }).then((result: any) => {
      this.parent_plan = result.data[0]
    })
  }

  public rows(context: any): Promise<any> {
    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key].toLowerCase()}`)

    let query = [...context.filter, ...field_filters]

    query.push(`year:${this.year}`)

    if (this.region) {
      query.push(`region:${this.region}`)
    }
    if (this.agency) {
      query.push(`agency_id:${this.agency}`)
    }

    query.push(`scope:${this.scope}`)

    this.loadWidgets()
    this.overviewTable.refresh()

    return FinancialPlan.paginate({
      page_size: this.scope === 'sales_rep' ? 'all' : context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query,
      // @ts-ignore
      snapshot: this.snapshot_id,
      calculate_totals: true,
    }).then((result: any) => {
      this.records = result.records

      if (result.data && result.data.length > 0 && result.data[0].agency) {
        this.agency_name = result.data[0].agency.name
      }

      this.loading = false
      this.ready = true

      this.items = result.data
      let ret: any[] = []

      if (this.scope === 'sales_rep') {
        let tmp: any = groupBy(result.data, item => `${item.sales_rep.name}+${item.product}`)
        ret = Object.keys(tmp).map(key => {
          let item = FinancialPlan.toObject(tmp[key][0])
          item.goal = tmp[key].reduce((a, b) => a + b.goal, 0)
          item.goal_planned = tmp[key].reduce((a, b) => a + b.goal_planned, 0)
          item.goal_pipeline = tmp[key].reduce((a, b) => a + b.goal_pipeline, 0)
          item.goal_achieved = tmp[key].reduce((a, b) => a + b.goal_achieved, 0)
          item.last_year_goal = tmp[key].reduce((a, b) => a + b.last_year_goal, 0)
          item.last_year_goal_planned = tmp[key].reduce((a, b) => a + b.last_year_goal_planned, 0)
          item.last_year_goal_pipeline = tmp[key].reduce((a, b) => a + b.last_year_goal_pipeline, 0)
          item.last_year_goal_achieved = tmp[key].reduce((a, b) => a + b.last_year_goal_achieved, 0)
          return item
        })

        ret = FinancialPlan.toObjectList(ret)

        this.records = ret.length
      } else {
        ret = FinancialPlan.toObjectList(result.data)
      }

      return clone(ret)
    })
  }

  public cancel() {
    this.$router.push({ name: 'GoalHome' })
  }

  public addPlan() {
    this.sidebar = false

    this.model = FinancialPlan.toObject({
      year: this.year,
      parent_id: this.parent_plan.id,
      region: this.parent_plan.region,
      agency_id: this.parent_plan.agency_id,
    })

    this.model.type = `${this.page_scope}_goal`

    this.sales_rep_items = []
    if (this.scope === 'sales_rep') {
      this.sales_rep_items = this.buildBaseSalesRepItems()
    }

    this.locked_items = []
    this.sidebar = true
    this.is_side_bar_open = true
    this.preview_region = false
  }

  public editPlan(plan: FinancialPlan) {
    this.sidebar = false

    this.model = FinancialPlan.toObject(plan)

    this.sales_rep_items = []

    if (this.scope === 'sales_rep') {
      this.sales_rep_items = this.buildBaseSalesRepItems()
      this.items
        .filter(
          (item: any) =>
            item.sales_rep_id === this.model.sales_rep_id && item.product === this.model.product,
        )
        .forEach((item: any) => {
          let date = moment(item.period_start_at).format('YYYY-MM-DD')
          let idx = this.sales_rep_items.findIndex((i: any) => i.period_start_at === date)

          if (idx >= 0) {
            this.sales_rep_items[idx] = FinancialPlan.toObject(item)
          }
        })

      Vue.set(this, 'sales_rep_items', FinancialPlan.toObjectList(this.sales_rep_items))
    }
    this.locked_items = []
    this.sidebar = true
    this.is_side_bar_open = true
    this.preview_region = false
  }

  public save() {
    this.loading = true
    if (this.scope === 'sales_rep') {
      this.sales_rep_items = this.sales_rep_items.map(item => {
        item.parent_id = this.parent_plan.id
        item.year = this.model.year
        item.region = this.model.region
        item.agency_id = this.model.agency_id
        item.sales_rep_id = this.model.sales_rep_id
        item.product = this.model.product
        item.type = this.model.type
        item.period_start_at = moment(item.period_start_at).format('YYYY-MM-DD')
        item.period_end_at = moment(item.period_end_at).format('YYYY-MM-DD')
        return item
      })
      FinancialPlan.batchSave(this.sales_rep_items).then(() => {
        this.sidebar = false
        this.loading = false
        this.is_side_bar_open = false
        this.dataTable.refresh()
      })
      return
    }
    this.model.save().then(() => {
      this.sidebar = false
      this.loading = false
      this.is_side_bar_open = false
      this.dataTable.refresh()
    })
  }

  public deletePlan(item: FinancialPlan) {
    WebMessage.confirm(
      'Are you sure that you want to delete this record? This action will also delete all sub records.',
      'Delete Plan',
      { okTitle: 'Delete' },
    ).then((value: boolean) => {
      if (value) {
        this.loading = true
        item.delete().then(response => {
          if (response.data.result === 'cleared') {
            WebMessage.warning(
              'The record has been cleared, but it can not be deleted as it has data associated in the pipeline or in last year.',
            )
          } else {
            WebMessage.success('Plan Deleted successfully!')
          }

          this.loading = false
          this.dataTable.refresh()
        })
      }
    })
  }

  public viewDetails() {
    this.sidebar = true
  }

  public viewOpportunity(item) {
    Vue.set(this, 'temp_sales_rep', {
      data: item.sales_rep,
      query: [`sales_rep_id:${item.sales_rep_id}`, `agency_id:${this.agency}`],
    })
    setTimeout(() => {
      this.modals.sales_rep_opportunity = true
    }, 500)
  }

  public seekGoal(item: FinancialPlan) {
    this.model = item
    this.seek_goal.action = 'match'
    this.modals.goal_seek = true
    this.locked_items = []
    this.advanced_checksum = ''
  }

  public clearGoalSeek() {
    this.model = new FinancialPlan()
    this.modals.goal_seek = false
    this.resetSeekGoalBase()
  }

  public onSeekGoalChange(val: any, origem: string) {
    if (origem === 'source') {
      if (val === 'goal') {
        this.seek_goal.target = 'planned'
      } else {
        this.seek_goal.target = 'goal'
      }
    }

    if (origem === 'target') {
      if (val === 'goal') {
        this.seek_goal.source = 'planned'
      } else {
        this.seek_goal.source = 'goal'
      }
    }
  }

  public syncGoal() {
    this.loading = true
    this.seek_goal.loading = true

    if (this.seek_goal.action === 'advanced') {
      let payload: FinancialPlan[] = []

      for (let period in this.advanced_models) {
        for (let rep in this.advanced_models[period]) {
          payload.push(FinancialPlan.toObject(this.advanced_models[period][rep]))
        }
      }

      if (this.advanced_delete.length > 0) {
        return FinancialPlan.batchDelete(this.advanced_delete)
          .then(response => {
            this.advanced_delete = []

            let cleared = response.data.result === 'cleared'

            return FinancialPlan.batchSave(payload)
              .then(() => {
                this.loading = false
                this.seek_goal.loading = false
                this.modals.goal_seek = false

                let msg = 'Plan synced successfully!'

                if (cleared) {
                  msg
                    += 'Some record could not be deleted as they have data associated in the pipeline or in last year.'
                }

                WebMessage.success(msg)

                this.dataTable.refresh()
              })
              .catch(() => {
                this.loading = false
                this.seek_goal.loading = false
                this.modals.goal_seek = false
              })
          })
          .catch(() => {
            this.loading = false
            this.seek_goal.loading = false
            this.modals.goal_seek = false
          })
      }

      return FinancialPlan.batchSave(payload).then(() => {
        this.loading = false
        this.seek_goal.loading = false
        this.modals.goal_seek = false

        this.dataTable.refresh()
      })
    }

    this.model
      .syncGoal(this.seek_goal)
      .then(() => {
        this.loading = false
        this.modals.goal_seek = false
        this.refresh()
        this.resetSeekGoalBase()
      })
      .catch(() => {
        this.loading = false
        this.modals.goal_seek = false
        this.resetSeekGoalBase()
      })
  }

  public resetSeekGoalBase() {
    this.advanced_checksum = ''
    this.seek_goal = {
      action: 'set',
      target: 'goal',
      source: 'planned',
      product: 'ssl',
      amount: 0,
      loading: false,
      sub_loading: false,
    }
  }

  public clearTempSalesRep() {
    Vue.set(this, 'temp_sales_rep', {
      data: null,
      query: null,
    })
  }

  public calculateTotals() {
    // must create a new call so it doents create vue proxy to avoid(deep link bug)
    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key].toLowerCase()}`)

    let query = [...(this.dataTable.dataTable.filter ?? []), ...field_filters]

    query.push(`year:${this.year}`)

    if (this.region) {
      query.push(`region:${this.region}`)
    }
    if (this.agency) {
      query.push(`agency_id:${this.agency}`)
    }

    query.push(`scope:${this.scope}`)

    FinancialPlan.calculateTotals({
      page_size: this.scope === 'sales_rep' ? 'all' : this.dataTable.perPage,
      page: this.dataTable.page,
      order_by: this.dataTable.sortBy,
      order: this.dataTable.sortDesc ? 'desc' : 'asc',
      query,
      // @ts-ignore
      snapshot: this.snapshot_id,
      // @ts-ignore
      calculate_totals: true,
    }).then((result: any) => {
      this.calculated_totals = result.data
    })
  }
}
