import router from '@/Routes'
import FinancialPlan from '@/models/FinancialPlan'

export default [
  {
    key: 'agency',
    label: 'Client',
    sortable: true,
    filter: true,
    show: true,
    thClass: 'align-middle text-center',
    tdClass: 'align-middle text-center',
    type: 'object',
    object: {
      key: 'agency',
      property: 'name',
    },
  },
  {
    key: 'last_year_goal_achieved',
    label: 'Last Year Achieved',
    sortable: true,
    filter: true,
    show: true,
    type: 'currency',
    thClass: 'align-middle text-center',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'view_goal',
    label: 'Plan',
    sortable: true,
    filter: true,
    show: true,
    thClass: 'align-middle text-center',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'view_goal_planned',
    label: 'Pending Plan',
    sortable: true,
    filter: true,
    show: true,
    thClass: 'align-middle text-center',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'view_goal_pipeline',
    label: 'Pipeline',
    sortable: true,
    filter: true,
    show: true,
    thClass: 'align-middle text-center',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'view_goal_sold',
    label: 'Sold',
    sortable: true,
    filter: true,
    show: true,
    thClass: 'align-middle text-center',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'view_goal_achieved',
    label: 'Achieved',
    sortable: true,
    filter: true,
    show: true,
    thClass: 'align-middle text-center',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'share',
    label: 'Share',
    sortable: true,
    filter: false,
    show: true,
    thClass: 'align-middle text-center',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'action',
    label: '',
    type: 'action',
    sortable: false,
    show: true,
    tdClass: 'actions',
    thStyle: { width: '160px' },
    actions: [
      {
        icon: 'eye',
        title: 'View Details',
        event: 'load_details',
        allow_on_snapshot: true,
        action: (plan: FinancialPlan) => {
          let query :any = {}
          if (plan.snapshot_id) {
            query.snapshot = plan.snapshot_id
          }

          // @ts-ignore
          router.push({
            name: 'GoalPlan',
            params: { year: plan.year, region: plan.region, agency: plan.agency_id },
            query,
          })
        },
        top_level: true,
      },
      {
        icon: 'pencil',
        title: 'Edit Year Goal',
        event: 'edit',
        top_level: true,
      },
      {
        icon: 'bullseye',
        title: 'Goal Seek',
        event: 'goal-seek',
        top_level: true,
      },
      {
        icon: 'trash',
        title: 'Delete Year Goal',
        event: 'delete',
        top_level: true,
      },
      // {
      //   icon: 'chart-area',
      //   title: 'General Overview',
      //   event: 'overview',
      //   top_level: true,
      //   action: (plan: FinancialPlan) => {
      //     // @ts-ignore
      //     router.push({
      //       name: 'GoalOverView',
      //       params: { year: plan.year, region: plan.region, agency: plan.agency_id },
      //     })
      //   },
      // },
    ],
  },
]
